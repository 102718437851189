import React, { useState } from "react";

const Table = ({ history, setPlayerHistory, filteredabbr, allRoles }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;
    const totalPages = Math.ceil(history?.length / ITEMS_PER_PAGE);

    const GetRoleAbbr = (role) => {
        const roleAbbr = allRoles.find(item => item.role === role)?.short;

        return roleAbbr;
    }

    // Get the current page's data
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = history?.slice(indexOfFirstItem, indexOfLastItem);
    return (
        <div className="metric_main_content  bg-white rounded-lg px-6">
            <div class="flex justify-between items-center py-4 border-b">
                <h6 class="font-semibold 2xl:text-base">{history?.length} Results</h6>
                <div class="flex gap-2">
                    <button class="px-2 py-1 flex border border-white font-[600] justify-center items-center  text-white rounded cursor-default text-xs">
                        Reset
                    </button>
                    <button class="px-2 py-1 flex  text-white justify-center items-center rounded cursor-default  text-xs">
                        Apply
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="table table-xs w-full">
                    <thead>
                        <tr className="text-neutral-content font-semibold text-xs">
                            <th className="px-0">PLAYER</th>
                            <th className="px-0">TEAM</th>
                            <th className="px-2 text-center">AGE</th>
                            <th className="px-2 text-center">POS</th>
                            <th className="px-2 text-center">ROLE</th>
                            <th className="px-2 text-center">MINS</th>
                            <th className="px-2 text-center">Leag</th>
                            <th className="px-2 text-center">CTR END</th>
                            <th className="px-0 text-center">POT</th>
                            {filteredabbr?.map((item, index) => (
                            <th className="px-0 text-center bg-red-400" key={index}>
                                {item.shortName}
                            </th>
                            ))}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((player, index) => (
                            <tr key={index}>
                                <td className="font-semibold">
                                    {player.player_dob.split(' ')[0][0]}{"."}
                                    {player.player_dob.split(' ')[player.player_dob.split(' ').length - 2]}
                                    {/* V. Osimhen */}
                                </td>
                                <td className="text-neutral-content">
                                    {player.team}
                                </td>
                                <td className="text-center font-semibold">{player.age}</td>
                                <td className="text-center font-semibold">
                                    {player.pos === 'Forward (CF)' ? 'FWD' :  ""}
                                    {player.pos === 'Forward (Wing)' ? 'W' :  ""}
                                    {player.pos === 'Midfield (Center, Offensive)' ? 'AM' :  ""}
                                    {player.pos === 'Midfield (Defensive)' ? 'DM' :  ""}
                                    {player.pos === 'Defenders (Central)' ? ' CB' :  ""}
                                    {player.pos === 'Defender (Wide)' ? 'FB' :  ""}
                                    {player.pos === 'Goalkeepers' ? 'GK' :  ""}
                                </td>
                                <td className="text-center text-neutral-content">
                                    {/* {player.roles.split(' ')[0][0]}-{player.roles.split(' ')[1][0]} */}

                                    {GetRoleAbbr(player.roles)}
                                    
                                </td>
                                <td className="text-center text-neutral-content">
                                    {player.season_minutes}
                                </td>
                                <td className="text-center text-neutral-content">
                                    {player.league_code}
                                </td>
                                <td className="text-center text-neutral-content">
                                    {player.expiration_date?.split('-')[0]}
                                </td>
                                <td className="font-semibold pl-3 pr-1 relative">
                                    <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded mx-auto">
                                        {player.pot}
                                    </div>
                                    <div className="absolute w-[1px] h-[24px] bg-[#F2F3F6] left-0 top-2/4 mt-[-12px] bottom-2"></div>
                                </td>
                                {filteredabbr?.map((item, index) => (
                                <td className="font-semibold px-1" key={index}>
                                    <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded mx-auto ">
                                        {player?.[item.apikey]>0 ? '+'+player?.[item.apikey] : player?.[item.apikey]}
                                    </div>
                                </td>
                                ))}
                                <td className="font-semibold pl-1 pr-3 relative">
                                    <span className="w-[32px] h-[32px] flex justify-center items-center text-primary evaluations_count rounded mx-auto">{player.evaluations_count}</span>
                                    <div className="absolute w-[1px] h-[24px] bg-[#F2F3F6] left-0 top-2/4 mt-[-12px] bottom-2"></div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;
