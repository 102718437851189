import React, { useCallback, useContext, useEffect, useState } from "react";
import Result from "./Result/Result";
import "./Coach2.css";
import Leagues from "./Dropdown/Leagues";
import Seasons from "./Dropdown/Seasons";
import Teams from "./Dropdown/Teams";
import loader from "../../Images/loader.gif";
import { BiSearch } from "react-icons/bi";
import { debounce } from "lodash";
import { DataContext } from "../../Context/DataProvider";
import Position from "../Team/dropdown/Position";
import Positions from "./Dropdown/Positions";
import Role from "./Dropdown/Role";
import ContactExpiring from "./Dropdown/ContactExpiring";
import TeamQuality from "./Dropdown/TeamQuality";
import PlayerPotential from "./Dropdown/PlayerPotential";
import Table from "./Table";
import playersData from "../../players.json";

const Survey = () => {
    const [loading, setLoading] = useState(false);
    const [resultLoading, setResultLoading] = useState(false);
    const [playerHistory, setPlayerHistory] = useState([]);
    const [teamSelected, setTeamSelected] = useState("");
    const [leagueSelected, setLeagueSelected] = useState("");
    const [seasonSelected, setSeasonSelected] = useState("");
    const [selectedTrait, setSelectedTrait] = useState("");
    const [selectedLeague, setSelectedLeague] = useState("");
    const [selectedSeason, setSelectedSeason] = useState("");
    const [selectedTeam, setSelectedTeam] = useState("");
    const [age1, setAge1] = useState("");
    const [age2, setAge2] = useState("");
    const [game1, setGame1] = useState("");
    const [game2, setGame2] = useState("");
    const [totalPage, setTotalPage] = useState(null);
    const [page, setPage] = useState(1);
    const [links, setLinks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [notFound, setNotFound] = useState(false);
    const [activeTraits, setActiveTraits] = useState([]);
    const [callApi, setCallApi] = useState(false);
    const [selectedLeagues, setSelectedLeagues] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedSeasons, setSelectedSeasons] = useState([]);
    const [lastPage, setLastPage] = useState(null);
    const [bpRangeValue, setBpRangeValue] = useState(null);
    const [scRangeValue, setScRangeValue] = useState(null);
    const [ballRetRangeValue, setBallRetRangeValue] = useState(null);
    const [defFreRangeValue, setDefFreRangeValue] = useState(null);
    const [defImpRangeValue, setDefImpRangeValue] = useState(null);
    const [bpRangeSlide, setBpRangeSlide] = useState(false);
    const [scRangeSlide, setScRangeSlide] = useState(false);
    const [ballRetRangeSlide, setBallRetRangeSlide] = useState(false);
    const [defFreRangeSlide, setDefFreRangeSlide] = useState(false);
    const [defImpRangeSlide, setDefImpRangeSlide] = useState(false);
    const [teams, setTeams] = useState([]);
    const [serverError, setServerError] = useState(null);
    const [leagueApiCall, setLeagueApiCall] = useState(false);
    const [searchedText, setSearchedText] = useState("");
    const [players, setPlayers] = useState(null);
    const [playerName, setPlayerName] = useState("");
    const [searchDropdown, setSearchDropdown] = useState(false);
    const { base_url, token } = useContext(DataContext);

    const [applyButton, setApplyButton] = useState(true);

    const [positionSelected, setPositionSelected] = useState("");
    const [roleSelected, setRoleSelected] = useState("");
    const [contractSelected, setContractSelected] = useState("");
    const [teamQualitySelected, setTeamQualitySelected] = useState("");
    const [selectedTeamQualities, setSelectedTeamQualities] = useState("");
    const [playerPotentialSelected, setPlayerPotentialSelected] = useState("");
    const [selectedPlayerPotentials, setSelectedPlayerPotentials] = useState("");
    const [filteredabbr, setFilteredabbr] = useState([]);
    const [formValues, setFormValues] = useState({});

    
    const allRoles = [
        { position: "Forward (CF)", role: "Target Man", short: "(T-CF)"},
        { position: "Forward (CF)", role: "Playmaking CF", short: "(P-CF)" },
        { position: "Forward (CF)", role: "Second Striker", short: "(SS)" },
        { position: "Forward (CF)", role: "Run-in-Behind CF", short: "(R-CF)" },
        { position: "Forward (Wing)", role: "Run-in-Behind Winger", short: "(R-W)" },
        { position: "Forward (Wing)", role: "Manipulator Winger", short: "(M-W)" },
        {
            position: "Midfield (Center, Offensive)",
            role: "Defensive MF/Central MF",
            short: "(D-MF)"
        },
        { position: "Midfield (Center, Offensive)", role: "Box to Box MF", short: "(B-MF)"},
        { position: "Midfield (Center, Offensive)", role: "Playmaking CAM", short: "(P-MF)"},
        { position: "Midfield (Center, Offensive)", role: "High Tempo CAM", short: "(HT-MF)" },
        { position: "Midfield (Defensive)", role: "Holding Defensive MF", short: "(H-DM)" },
        { position: "Midfield (Defensive)", role: "Playmaking Defensive MF", short: "(P-DM)" },
        {
            position: "Defenders (Central)",
            role: "Ball-Playing Central Defender",
            short: "(B-CB)"
        },
        { position: "Defenders (Central)", role: "Physical Central Defender", short: "(P-CB)" },
        {
            position: "Defenders (Central)",
            role: "Ball-Winning Central Defender",
            short: "(W-CB)"
        },
        { position: "Defenders (Central)", role: "Versatile Central Defender",short: "(V-CB)" },
        { position: "Defender (Wide)", role: "Technical Full Back", short: "(T-FB)" },
        { position: "Defender (Wide)", role: "Athletic Full Back", short: "(A-FB)" },
        { position: "Defender (Wide)", role: "Defensive Full Back (Stopper)", short: "(D-FB)" },
        { position: "Defender (Wide)", role: "Versatile Full Back", short: "(V-FB)" },
        { position: "Goalkeepers", role: "Ball-Playing GK", short: "(B-GK)" },
    ];

    useEffect(() => {
        // Check if any of the variables have a value, if so, setApplyButton to false
        if (
            positionSelected !== "" ||
            roleSelected !== "" ||
            selectedLeague !== "" ||
            selectedSeason !== "" ||
            selectedTeam !== "" ||
            contractSelected !== "" ||
            age1 !== "" ||
            age2 !== "" ||
            game1 !== "" ||
            game2 !== "" ||
            searchedText !== "" ||
            selectedTeamQualities !== "" ||
            selectedPlayerPotentials !== ""
        ) {
            setApplyButton(false);
        } else {
            setApplyButton(true);
        }
    }, [
        positionSelected,
        roleSelected,
        selectedLeague,
        selectedSeason,
        selectedTeam,
        contractSelected,
        age1,
        age2,
        game1,
        game2,
        searchedText,
        selectedTeamQualities,
        selectedPlayerPotentials,
    ]);

    const handleBpRangeChange = (event) => {
        setBpRangeValue(parseFloat(event.target.value));
        setBpRangeSlide(true);
    };
    const handleScRangeChange = (event) => {
        setScRangeValue(parseFloat(event.target.value));
        setScRangeSlide(true);
    };
    const handleBallRetRangeChange = (event) => {
        setBallRetRangeValue(parseFloat(event.target.value));
        setBallRetRangeSlide(true);
    };
    const handleDefFreRangeChange = (event) => {
        setDefFreRangeValue(parseFloat(event.target.value));
        setDefFreRangeSlide(true);
    };
    const handleDefImpRangeChange = (event) => {
        setDefImpRangeValue(parseFloat(event.target.value));
        setDefImpRangeSlide(true);
    };

    function getColor(step, name) {
        console.log(parseInt(step) === parseInt(formValues?.[name]));
        console.log('nameValue: ',formValues?.[name]);
        console.log('step: ',step);
        
        if (formValues?.[name] === undefined) {
            return "bg-white border-2 border-gray-400";
        }
        if (parseInt(step) === parseInt(formValues?.[name])) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getText(name) {

        if(parseInt(formValues?.[name]) === -3){
            return "Very Poor"
        }                                  
        if(parseInt(formValues?.[name]) === -2){
            return "Poor"
        }                                  
        if(parseInt(formValues?.[name]) === -1){
            return "Below Average"
        }                                  
        if(parseInt(formValues?.[name]) === 1){
            return "Above Average"
        }                                  
        if(parseInt(formValues?.[name]) === 2){
            return "Good"
        }                                  
        if(parseInt(formValues?.[name]) === 3){
            return "Excellent"
        }                                  
         
        if (formValues?.[name] === undefined) {
            return ;
        }
        
    }
    console.log('form:' ,formValues);
    function getBpDotColor(step, name) {
        if (formValues?.[name] === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === formValues?.[name]) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }

    function getScDotColor(step) {
        if (scRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === scRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getBallRetDotColor(step) {
        if (ballRetRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === ballRetRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getDefFreDotColor(step) {
        if (defFreRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === defFreRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }
    function getDefImpDotColor(step) {
        if (defImpRangeValue === null) {
            return "bg-white border-2 border-gray-400";
        }
        if (step === defImpRangeValue) {
            return "bg-primary";
        }
        return "bg-white border-2 border-gray-400";
    }

    useEffect(() => {
        if (
            leagueSelected === "select league" &&
            selectedLeagues.length === 0
        ) {
            setSelectedLeague("");
        } else {
            setSelectedLeague(selectedLeagues.join(","));
        }
    }, [leagueSelected, selectedLeagues]);

    useEffect(() => {
        if (teamSelected === "select team" && selectedTeams.length === 0) {
            setSelectedTeam("");
        } else {
            setSelectedTeam(selectedTeams.join(","));
        }
    }, [teamSelected, selectedTeams]);

    useEffect(() => {
        if (
            seasonSelected === "select season" &&
            selectedSeasons.length === 0
        ) {
            setSelectedSeason("");
        } else {
            setSelectedSeason(selectedSeasons.join(","));
        }
    }, [seasonSelected, selectedSeasons]);

    useEffect(() => {
        if (callApi) {

            const generateUrlParams = () => {
                let params = '';
                for (const key in formValues) {
                  if (formValues.hasOwnProperty(key)) {
                    params += `${key}=${formValues[key]}&`;
                  }
                }
                // Remove the trailing '&' if present
                params = params.slice(0, -1);
                return params;
              };
            
              // Generate the URL parameters string
              const urlParams = generateUrlParams();

            setLoading(true);
            fetch(
                `${base_url}/api/v1/survey-result?page=${page}&position=${positionSelected}&role=${roleSelected}&league=${selectedLeague}&season=${selectedSeason}&team=${selectedTeam}&contract=${contractSelected}&min_age=${age1}&max_age=${age2}&min_minutes=${game1}&max_minutes=${game2}&name=${
                    playerName ? playerName : searchedText
                }&current_level=${selectedTeamQualities}&potential=${selectedPlayerPotentials}${urlParams ? `&${urlParams}` : ''}`
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data) {
                        setPlayerHistory(data);
                        // setLinks(data?.links);
                        // setCurrentPage(data?.currentPage);
                        // setTotalPage(data?.total);
                        // setLastPage(data?.last_page);
                        setLoading(false);
                        setCallApi(false);
                        setServerError(null);
                        setResultLoading(false);
                    }

                    if (data?.length === 0) {
                        setLoading(false);
                        setNotFound(true);
                        setResultLoading(false);
                    }
                })
                .catch((error) => {
                    console.log('Error:' ,error);
                    setLoading(false);
                    setServerError("Error from server");
                });
        }
    }, [
        page,
        selectedLeague,
        selectedSeason,
        selectedTeam,
        callApi,
        selectedTrait,
        age1,
        age2,
        game1,
        game2,
        bpRangeValue,
        scRangeValue,
        ballRetRangeValue,
        defFreRangeValue,
        defImpRangeValue,
    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        setFormValues({ ...formValues, [name]: value ? value : "" });
    };
    
    const handleFilterApply = () => {
        setPlayerHistory([]);
        setServerError(null);
        setCallApi(true);
        setResultLoading(true);
    };

    const handleResetFilter = () => {
        setPlayerHistory([]);
        setActiveTraits([]);
        setLeagueSelected("");
        setSelectedLeagues([]);
        setSelectedSeasons([]);
        setSelectedTeams([]);
        setNotFound(false);
        setServerError();
        setAge1("");
        setAge2("");
        setGame1("");
        setGame2("");
        setBpRangeValue(null);
        setScRangeValue(null);
        setBallRetRangeValue(null);
        setDefFreRangeValue(null);
        setDefImpRangeValue(null);
        setSearchedText("");
        setPlayerName("");

        setPositionSelected("");
        setRoleSelected("");
        setContractSelected("");
        setTeamQualitySelected("");
        setSelectedTeamQualities("");
        setPlayerPotentialSelected("");
        setSelectedPlayerPotentials("");
        setFilteredabbr([]);
        setFormValues({});
    };

    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (
                !e.target.closest(".player-list") &&
                !e.target.closest(".search-box")
            ) {
                setSearchDropdown(false);
            }
        });
    }, []);

    const handlePlayerDetail = (name) => {
        setPlayerName(name);
        setSearchedText(name);
        setSearchDropdown(false);
        // setResultLoading(true);
    };

    useEffect(() => {
        if (searchedText === "") {
            setPlayers(null);
        }
    }, [searchedText]);

    const handleSearch = useCallback(() => {
        if (searchedText !== "") {
            setLoading(true);

            //   fetch(`${base_url}/api/v1/coach/search?keywords=${searchedText}`, {
            //     headers: {
            //       "Content-type": "application/json; charset=UTF-8",
            //       Authorization: `Bearer ${token}`,
            //     },
            //   })
            //     .then((res) => res.json())
            //     .then((data) => {
            //       setPlayers(data);
            //       setLoading(false);
            //       setNotFound(false);
            //     });
            const searchWords = searchedText.trim().split(/\s+/);
            const filteredResults = playersData.filter((player) => {
                const { name_team } = player;
                const lowerCaseName = name_team.toLocaleLowerCase();

                return searchWords.every((word) =>
                    lowerCaseName.includes(word.toLowerCase())
                );
            });
            setPlayers(filteredResults.slice(0, 10));
            setLoading(false);
            setNotFound(false);
        } else {
            setLoading(false);
        }
    }, [searchedText]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch(searchedText);
        }, 1000);

        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchedText, handleSearch]);

    // const debouncedSearch = debounce(handleSearch, 1000);

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchedText(value);
        setPlayerName("");
        setSearchDropdown(true);
    };

    

    let content;
    if (loading && players === null && searchedText.length > 0) {
        content = <progress className="progress loading w-56"></progress>;
    }

    if (
        players?.length === 0 &&
        searchedText.length > 0 &&
        searchDropdown === true
    ) {
        content = <p className="not-found">No data found !</p>;
    }

    if (
        players?.length > 0 &&
        !loading &&
        players !== null &&
        searchedText.length > 0 &&
        searchDropdown === true
    ) {
        content = (
            <ul className=" max-h-[300px] overflow-y-scroll b z-50 shadow-lg bg-base-100 p-2 rounded-box">
                <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">
                    COACHES
                </h6>
                {players?.map((player, index) => (
                    <li
                        key={index}
                        onClick={() => handlePlayerDetail(player.name_dob)}
                    >
                        <div className="p-3 pt-2">
                            <div className="text-sm cursor-pointer">
                                <h6 className="text-semibold">
                                    {player.name_dob}
                                </h6>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <div className=" pt-5 md:pt-10 min-h-[90vh] text-neutral">
            {/* mobile sidebar filter start */}
            <div className="ms-[18px] hidden mb-4">
                <button className="bg-primary capitalize font-medium btn btn-sm btn-primary text-white">
                    <label htmlFor="apply-filter" className=" btn-create-team">
                        Apply Filter
                    </label>
                </button>
            </div>
            <div>
                <input
                    type="checkbox"
                    id="apply-filter"
                    className="modal-toggle"
                />
                <div className="modal">
                    <div className="modal-box apply-filter p-0">
                        <div className="apply-filter-header flex justify-between p-5">
                            <h3 className="font-bold text-base md:text-lg">
                                Apply Filter
                            </h3>
                            <label htmlFor="apply-filter">
                                <div className="font-semibold rounded-full w-[25px] shadow-lg flex justify-center items-center h-[25px] bg-[#f8f8f8] 2xl:text-base">
                                    <div className="mt-[-4px]">x</div>
                                </div>
                            </label>
                        </div>
                        <div>
                            {/* bg base */}
                            <div className=" coach-sidebar bg-base-100 rounded-lg">
                                {/*  Header */}
                                <div className="px-6 flex justify-between items-center py-4 border-b">
                                    <h6 className="font-semibold 2xl:text-base">
                                        Filters
                                    </h6>
                                    <div className="flex gap-2 ">
                                        <button
                                            disabled={!applyButton}
                                            onClick={() => handleResetFilter()}
                                            className={`px-2 py-1 flex ${
                                                !applyButton
                                                    ? "bg-transparent border"
                                                    : "border font-[600]"
                                            } justify-center items-center  text-black rounded  text-xs`}
                                        >
                                            Reset
                                        </button>
                                        <button
                                            disabled={
                                                selectedLeagues.length === 0 &&
                                                selectedSeasons.length === 0 &&
                                                selectedTeams.length === 0 &&
                                                age1 === "" &&
                                                game1 === "" &&
                                                bpRangeValue === null &&
                                                scRangeValue === null &&
                                                ballRetRangeValue === null &&
                                                defFreRangeValue === null &&
                                                defImpRangeValue === null &&
                                                searchedText === "" &&
                                                playerName === ""
                                            }
                                            onClick={() => handleFilterApply()}
                                            className={`px-2 py-1 flex ${
                                                selectedLeagues.length === 0 &&
                                                selectedSeasons.length === 0 &&
                                                selectedTeams.length === 0 &&
                                                age1 === "" &&
                                                game1 === "" &&
                                                bpRangeValue === null &&
                                                scRangeValue === null &&
                                                ballRetRangeValue === null &&
                                                defFreRangeValue === null &&
                                                defImpRangeValue === null &&
                                                searchedText === "" &&
                                                playerName === ""
                                                    ? "bg-transparent text-black border"
                                                    : "bg-primary text-white"
                                            } justify-center items-center rounded   text-xs`}
                                        >
                                            {selectedLeagues.length === 0 &&
                                            selectedSeasons.length === 0 &&
                                            selectedTeams.length === 0 &&
                                            age1 === "" &&
                                            game1 === "" &&
                                            bpRangeValue === null &&
                                            scRangeValue === null &&
                                            ballRetRangeValue === null &&
                                            defFreRangeValue === null &&
                                            defImpRangeValue === null &&
                                            searchedText === "" &&
                                            playerName === "" ? (
                                                "Apply"
                                            ) : (
                                                <label htmlFor="apply-filter">
                                                    Apply
                                                </label>
                                            )}
                                        </button>
                                    </div>
                                </div>

                                <div className="p-2.5 2xl:p-4 border-b">
                                    <div className="w-full search-box relative text-sm mb-4 lg:mb-0">
                                        <input
                                            type="text"
                                            name=""
                                            value={searchedText}
                                            onChange={handleSearchChange}
                                            className="w-full input-border p-3 pl-10 rounded-lg outline-none"
                                            placeholder="Search for a player"
                                        />

                                        <div className="absolute top-4 left-4 text-lg">
                                            <BiSearch />
                                        </div>
                                    </div>
                                    {content}
                                    {/* PLAYER DETAILS */}
                                    <div className="mt-4">
                                        <h6 className="text-neutral-content font-semibold text-xs">
                                            PLAYER DETAILS
                                        </h6>

                                        <Leagues
                                            leagueSelected={leagueSelected}
                                            setLeagueSelected={
                                                setLeagueSelected
                                            }
                                            setSeasonSelected={
                                                setSeasonSelected
                                            }
                                            setTeamSelected={setTeamSelected}
                                            setPage={setPage}
                                            selectedLeagues={selectedLeagues}
                                            setSelectedLeagues={
                                                setSelectedLeagues
                                            }
                                            setTeams={setTeams}
                                            setSelectedTeams={setSelectedTeams}
                                            setSelectedLeague={
                                                setSelectedLeague
                                            }
                                            setLeagueApiCall={setLeagueApiCall}
                                        />
                                        <Seasons
                                            seasonSelected={seasonSelected}
                                            setSeasonSelected={
                                                setSeasonSelected
                                            }
                                            leagueSelected={leagueSelected}
                                            selectedSeasons={selectedSeasons}
                                            setSelectedSeasons={
                                                setSelectedSeasons
                                            }
                                            setPage={setPage}
                                        />

                                        <Teams
                                            teamSelected={teamSelected}
                                            setTeamSelected={setTeamSelected}
                                            leagueSelected={leagueSelected}
                                            selectedLeague={selectedLeague}
                                            setPage={setPage}
                                            selectedTeams={selectedTeams}
                                            setSelectedTeams={setSelectedTeams}
                                            teams={teams}
                                            setTeams={setTeams}
                                            leagueApiCall={leagueApiCall}
                                        />

                                        <div className="mt-3">
                                            <h6 className="text-sm mb-1.5">
                                                Age
                                            </h6>
                                            <div className="flex gap-4 justify-between mt-1.5">
                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setAge1(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={age1}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5  rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>

                                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                                </div>

                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setAge2(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={age2}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <h6 className="text-sm mb-1.5">
                                                Games
                                            </h6>
                                            <div className="flex gap-4 justify-between mt-1.5">
                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setGame1(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={game1}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>

                                                <div className="flex justify-center items-center text-neutral-content font-medium">
                                                    <div className="w-2 h-[2px] bg-neutral-content"></div>
                                                </div>

                                                <div className="w-1/2">
                                                    <input
                                                        onChange={(e) =>
                                                            setGame2(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={game2}
                                                        className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                                        placeholder="Enter Value"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* PERFORMANCE */}
                                <div className="p-4">
                                    <h6 className="text-neutral-content font-semibold text-xs">
                                        PERFORMANCE
                                    </h6>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Level Coached
                                        </h6>
                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    bpRangeValue === null
                                                        ? ""
                                                        : bpRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={handleBpRangeChange}
                                                onClick={(e) => {
                                                    if (bpRangeValue === null) {
                                                        setBpRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full  ${getBpDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {bpRangeValue === null &&
                                            !bpRangeSlide
                                                ? "Not selected"
                                                : bpRangeValue === 0 &&
                                                  bpRangeSlide
                                                ? "Not Important"
                                                : bpRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Team Improvement
                                        </h6>

                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    scRangeValue === null
                                                        ? ""
                                                        : scRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={handleScRangeChange}
                                                onClick={(e) => {
                                                    if (scRangeValue === null) {
                                                        setScRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getScDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {scRangeValue === null &&
                                            !scRangeSlide
                                                ? "Not selected"
                                                : scRangeValue === 0 &&
                                                  scRangeSlide
                                                ? "Not Important"
                                                : scRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Impact on Off
                                        </h6>

                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    ballRetRangeValue === null
                                                        ? ""
                                                        : ballRetRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={
                                                    handleBallRetRangeChange
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        ballRetRangeValue ===
                                                        null
                                                    ) {
                                                        setBallRetRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getBallRetDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {ballRetRangeValue === null &&
                                            !ballRetRangeSlide
                                                ? "Not selected"
                                                : ballRetRangeValue === 0 &&
                                                  ballRetRangeSlide
                                                ? "Not Important"
                                                : ballRetRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Impact on Def
                                        </h6>
                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    defFreRangeValue === null
                                                        ? ""
                                                        : defFreRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={
                                                    handleDefFreRangeChange
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        defFreRangeValue ===
                                                        null
                                                    ) {
                                                        setDefFreRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getDefFreDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {defFreRangeValue === null &&
                                            !defFreRangeSlide
                                                ? "Not selected"
                                                : defFreRangeValue === 0 &&
                                                  defFreRangeSlide
                                                ? "Not Important"
                                                : defFreRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>
                                    <div className="mt-4 mb-5">
                                        <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                            Impact on PTS
                                        </h6>

                                        <div className="relative h-2 mt-4 mb-4">
                                            <input
                                                id="steps-range"
                                                type="range"
                                                min="0"
                                                max="2"
                                                value={
                                                    defImpRangeValue === null
                                                        ? ""
                                                        : defImpRangeValue
                                                }
                                                step="1"
                                                className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                                onChange={
                                                    handleDefImpRangeChange
                                                }
                                                onClick={(e) => {
                                                    if (
                                                        defImpRangeValue ===
                                                        null
                                                    ) {
                                                        setDefImpRangeValue(
                                                            parseFloat(
                                                                e.target.value
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                                {[0, 1, 2].map((step) => (
                                                    <div
                                                        key={step}
                                                        className={`w-4 h-4 rounded-full ${getDefImpDotColor(
                                                            step
                                                        )}`}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-[12px] 2xl:text-sm font-medium mt-1 text-neutral-content">
                                            {defImpRangeValue === null &&
                                            !defImpRangeSlide
                                                ? "Not selected"
                                                : defImpRangeValue === 0 &&
                                                  defImpRangeSlide
                                                ? "Not Important"
                                                : defImpRangeValue === 1
                                                ? "Somewhat Important"
                                                : "Very Important"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* mobile sidebar filter end */}

            <div className="metric flex">
                <div className="metric_sidebar coach-sidebar bg-base-100 rounded-lg">
                    {/*  Header */}
                    <div className="px-6 flex justify-between items-center py-4 border-b">
                        <h6 className="font-semibold 2xl:text-base">Filters</h6>
                        <div className="flex gap-2 ">
                            <button
                                disabled={applyButton}
                                onClick={() => handleResetFilter()}
                                className={`px-2 py-1 flex ${
                                    applyButton
                                        ? "bg-transparent border"
                                        : "border font-[600]"
                                } justify-center items-center  text-black rounded  text-xs`}
                            >
                                Reset
                            </button>
                            <button
                                disabled={applyButton}
                                onClick={() => handleFilterApply()}
                                className={`px-2 py-1 flex ${
                                    applyButton
                                        ? "bg-transparent text-black border"
                                        : "bg-primary text-white"
                                } justify-center items-center rounded   text-xs`}
                            >
                                Apply
                            </button>
                        </div>
                    </div>

                    <div className="p-2.5 2xl:p-4 border-b">
                        <div className="w-full search-box relative text-sm mb-4 lg:mb-0">
                            <input
                                type="text"
                                name=""
                                value={searchedText}
                                onChange={handleSearchChange}
                                className="w-full input-border p-3 pl-10 rounded-lg outline-none"
                                placeholder="Search for a player"
                            />

                            <div className="absolute top-4 left-4 text-lg">
                                <BiSearch />
                            </div>
                        </div>
                        {content}
                        {/* PLAYER DETAILS */}
                        <div className="mt-4">
                            <h6 className="text-neutral-content font-semibold text-xs">
                                PLAYER DETAILS
                            </h6>
                            <Positions
                                positionSelected={positionSelected}
                                setPositionSelected={setPositionSelected}
                                setFilteredabbr={setFilteredabbr}
                                setPlayerHistory={setPlayerHistory}
                                setFormValues={setFormValues}
                                setPage={setPage}
                            />
                            <Role
                                roleSelected={roleSelected}
                                setRoleSelected={setRoleSelected}
                                allRoles = {allRoles}
                                // setSeasonSelected={setSeasonSelected}
                                // setTeamSelected={setTeamSelected}
                                setPage={setPage}
                            />
                            <Leagues
                                leagueSelected={leagueSelected}
                                setLeagueSelected={setLeagueSelected}
                                setSeasonSelected={setSeasonSelected}
                                setTeamSelected={setTeamSelected}
                                setPage={setPage}
                                selectedLeagues={selectedLeagues}
                                setSelectedLeagues={setSelectedLeagues}
                                setTeams={setTeams}
                                setSelectedTeams={setSelectedTeams}
                                setSelectedLeague={setSelectedLeague}
                                setLeagueApiCall={setLeagueApiCall}
                            />
                            <Seasons
                                seasonSelected={seasonSelected}
                                setSeasonSelected={setSeasonSelected}
                                leagueSelected={leagueSelected}
                                selectedSeasons={selectedSeasons}
                                setSelectedSeasons={setSelectedSeasons}
                                setPage={setPage}
                            />

                            <Teams
                                teamSelected={teamSelected}
                                setTeamSelected={setTeamSelected}
                                leagueSelected={leagueSelected}
                                selectedLeague={selectedLeague}
                                setPage={setPage}
                                selectedTeams={selectedTeams}
                                setSelectedTeams={setSelectedTeams}
                                teams={teams}
                                setTeams={setTeams}
                                leagueApiCall={leagueApiCall}
                            />

                            <ContactExpiring
                                contractSelected={contractSelected}
                                setContractSelected={setContractSelected}
                                setSeasonSelected={setSeasonSelected}
                                setTeamSelected={setTeamSelected}
                                setPage={setPage}
                                selectedLeagues={selectedLeagues}
                                setSelectedLeagues={setSelectedLeagues}
                                setTeams={setTeams}
                                setSelectedTeams={setSelectedTeams}
                                setSelectedLeague={setSelectedLeague}
                                setLeagueApiCall={setLeagueApiCall}
                            />

                            <div className="mt-3">
                                <h6 className="text-sm mb-1.5">Age</h6>
                                <div className="flex gap-4 justify-between mt-1.5">
                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setAge1(e.target.value)
                                            }
                                            value={age1}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5  rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>

                                    <div className="flex justify-center items-center text-neutral-content font-medium">
                                        <div className="w-2 h-[2px] bg-neutral-content"></div>
                                    </div>

                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setAge2(e.target.value)
                                            }
                                            value={age2}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <h6 className="text-sm mb-1.5">Minutes</h6>
                                <div className="flex gap-4 justify-between mt-1.5">
                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setGame1(e.target.value)
                                            }
                                            value={game1}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>

                                    <div className="flex justify-center items-center text-neutral-content font-medium">
                                        <div className="w-2 h-[2px] bg-neutral-content"></div>
                                    </div>

                                    <div className="w-1/2">
                                        <input
                                            onChange={(e) =>
                                                setGame2(e.target.value)
                                            }
                                            value={game2}
                                            className="w-full px-3 py-2 min-[1920px]:py-2.5 rounded-md min-[1920px]:rounded-lg border text-xs  font-medium outline-none focus:border-primary"
                                            placeholder="Enter Value"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="divider"></div>
                        <div className="mt-4">
                            <h6 className="text-neutral-content font-semibold text-xs">
                                PLAYER EVALUATION
                            </h6>

                            <TeamQuality
                                teamQualitySelected={teamQualitySelected}
                                setTeamQualitySelected={setTeamQualitySelected}
                                selectedTeamQualities={selectedTeamQualities}
                                setSelectedTeamQualities={
                                    setSelectedTeamQualities
                                }
                                // setSeasonSelected={setSeasonSelected}
                                // setTeamSelected={setTeamSelected}
                                setPage={setPage}
                                // setTeams={setTeams}
                                // setSelectedTeams={setSelectedTeams}
                                // setSelectedLeague={setSelectedLeague}
                                // setLeagueApiCall={setLeagueApiCall}
                            />
                            <PlayerPotential
                                playerPotentialSelected={
                                    playerPotentialSelected
                                }
                                setPlayerPotentialSelected={
                                    setPlayerPotentialSelected
                                }
                                selectedPlayerPotentials={
                                    selectedPlayerPotentials
                                }
                                setSelectedPlayerPotentials={
                                    setSelectedPlayerPotentials
                                }
                                setPage={setPage}
                            />
                        </div>
                    </div>

                    {/* PERFORMANCE */}
                    {filteredabbr?.length>0 && (
                        <div className="p-4">
                        <h6 className="text-neutral-content font-semibold text-xs">
                            PERFORMANCE
                        </h6>

                        {filteredabbr?.map((abbr, index) => (
                            <div key={index} className="mt-6">
                                <h6 className="text-[13px] 2xl:text-sm font-medium mb-1">
                                    {abbr.fullname}
                                </h6>
                                <div className="flex gap-6 justify-between">
                                    <div className="basis-3/6 relative h-2 mt-4 mb-4">
                                        <input
                                            
                                            type="range"
                                            min="-3"
                                            max="-1"
                                            value={formValues?.[abbr.min_value]}
                                            step="1"
                                            name={abbr.min_value}
                                            className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                            onChange={handleChange}
                                            onClick={handleChange}
                                        />
                                        <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                            {[-3,-2,-1].map((idx) => (
                                                <div
                                                    key={idx}
                                                    className={`min_${abbr.min_value}_${idx} w-4 h-4 rounded-full ${getColor(idx, abbr.min_value)}`}
                                                >

                                                </div>
                                            ))}
                                        </div>
                                        <p className="text-xs font-medium mt-4 text-neutral-content">
                                            {getText(abbr.min_value)}
                                        </p>
                                    </div>
                                    <div className="basis-3/6 relative h-2 mt-4 mb-4">
                                        <input
                                           
                                            type="range"
                                            min="1"
                                            max="3"
                                            value={formValues?.[abbr.max_avlue]}
                                            step="1"
                                            name={abbr.max_avlue}
                                            className="absolute inset-0 w-full h-[75%] bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                            onChange={handleChange}
                                            onClick={handleChange}
                                        />
                                        <div className="absolute w-full h-[75%] flex justify-between items-center pointer-events-none">
                                            {[1, 2, 3].map((idx, idex) => (
                                                <div
                                                    key={idx}
                                                    className={`max_${abbr.max_avlue}_${idex} w-4 h-4 rounded-full ${getColor(idx, abbr.max_avlue)}`}
                                                >

                                                </div>
                                            ))}
                                        </div>
                                        <p className="text-xs font-medium mt-4 text-neutral-content">
                                            
                                            {getText(abbr.max_avlue)}
                                        </p>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        ))}
                    </div>
                    )}
                    
                </div>
                {/* Table */}
                {playerHistory.length > 0 && (
                    <Table
                        allRoles = {allRoles}
                        history={playerHistory}
                        setPlayerHistory={setPlayerHistory}
                        filteredabbr={filteredabbr}
                    />
                )}
                {resultLoading && page === 1 && (
                    <div
                        className="mt-[15%] mx-auto"
                    >
                        <img width={150} src={loader} alt="loading..." />
                    </div>
                )}

                {serverError && (
                    <div className="mt-[10px] ml-3 font-bold text-red-600 ">
                        {serverError}
                    </div>
                )}

                {!loading && notFound && playerHistory.length === 0 && (
                    <div className="mt-[10px] ml-3 font-bold text-red-600 ">
                        Result not found!
                    </div>
                )}
            </div>
        </div>
    );
};

export default Survey;
